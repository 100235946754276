//  Variations to Settings (simple)
//	----------------------
//
//  Table of Contents:
//
//	1. Color and palette variables
//	2. Top menu
//	3. Accordion
//	4. Tabs
//	5. Cards
//	6. Other bizazz settings
//	7. Overrides of foundation settings
//______________________________________

//	1. Color and palette variables
// -----------------------------------------------------------------------1

$colours: (
	colour1: (
		name: blue,
		title: Blue,
		text: #022670,
		textBackground: #fff,
		light: #022670,
		lighter: scale-color(#022670, $lightness: 80%),
		contrast: #fff,
	),
	colour2: (
		name: gold,
		title: Gold,
		text: scale-color(#fff2cc, $lightness: -52%),
		textBackground: #fff,
		light: scale-color(#fff2cc, $lightness: +25%),
		lighter: scale-color(#fff2cc, $lightness: 80%),
		contrast: color-pick-contrast(#fff2cc),
	),
	colour3: (
		name: grey,
		title: Bluey grey,
		text: scale-color(#deeaf6, $lightness: -52%),
		textBackground: #fff,
		light: scale-color(#deeaf6, $lightness: +25%),
		lighter: scale-color(#deeaf6, $lightness: 80%),
		contrast: color-pick-contrast(#deeaf6),
	),
	red: (
		name: red,
		title: Red,
		text: #f10000, /// #970610
		textBackground: #fff,
		light: scale-color(#f10000, $lightness: +25%),
		lighter: scale-color(#f10000, $lightness: 80%),
		contrast: color-pick-contrast(#f10000),
		textOnly: true,
		use: (text: true),

	),
	highlight: (
		name: highlight,
		title: Text highlight,
		text: $black,
		textBackground: #ff0,
		light: #ff0,
		lighter: #ff0,
		contrast: #ff0,
		textOnly: true,
		use: (text: true),
	),
	primary: (
		name: primary,
		title: Foundation primary,
		text: $primary-color,
		textBackground: #fff,
		light: scale-color($primary-color, $lightness: +25%),
		lighter: scale-color($primary-color, $lightness: 80%),
		contrast: color-pick-contrast($primary-color),
		foundation: true,
		use: (buttons: true),
	),
	secondary: (
		name: secondary,
		title: Foundation secondary,
		text: $secondary-color,
		textBackground: #fff,
		light: scale-color($secondary-color, $lightness: +25%),
		lighter: scale-color($secondary-color, $lightness: 80%),
		contrast: color-pick-contrast($secondary-color),
		foundation: true,
		use: (buttons: true),
	),
	success: (
		name: success,
		title: Foundation success,
		text: $success-color,
		textBackground: #fff,
		light: scale-color($success-color, $lightness: +25%),
		lighter: scale-color($success-color, $lightness: 80%),
		contrast: color-pick-contrast($success-color),
		foundation: true,
		use: (buttons: true),
	),
	alert: (
		name: alert,
		title: Foundation alert,
		text: $alert-color,
		textBackground: #fff,
		light: scale-color($alert-color, $lightness: +25%),
		lighter: scale-color($alert-color, $lightness: 80%),
		contrast: color-pick-contrast($alert-color),
		foundation: true,
		use: (buttons: true),
	),
	warning: (
		name: warning,
		title: Foundation warning,
		text: $warning-color,
		textBackground: #fff,
		light: scale-color($warning-color, $lightness: +25%),
		lighter: scale-color($warning-color, $lightness: 80%),
		contrast: color-pick-contrast($warning-color),
		foundation: true,
		use: (buttons: true),
	)
);

// Containers are used only for sites where only part of the content is constrained by grid-container.
// If any containers are defined here then the editor will not have the grid-container style added.
//$containers: (
//	container1: (
//		name: brown,
//		color: #f3e0b5,
//		background-color: #26150B,
//	),
//	container2: (
//		name: lightcream,
//		color: #000,
//		background-color: #f7edd9,
//	),
//	container3: (
//		name: orange,
//		color: #000,
//		background-color: #dd8c4b,
//	)
//);

@function getColour($scheme, $colour) {
	@return map-get(map-get($colours, $scheme), $colour);
}
// example use for above function; getColour(colour1, name);
// this 'gets' the colour1 name value, which equals aqua

//	2. Top menu
// -----------------------------------------------------------------------1

// Top menu colours and padding
$topmenu-background: #888;
//$topmenu-text: getColour(colour1, color);
$topmenu-text: #fff;
//$topmenu-current-text: getColour(colour2, color);
$topmenu-current-text: #fff;
$topmenu-hover: #333;
//$topmenu-white: #fff;
$topmenu-background-current: #444;
//$topmenu-colour-current: getColour(colour1, color);

$topmenu-title-padding: 0;
$topmenu-topbar-padding: 0.7rem 2rem;
$topmenu-text-decoration: none;

// Titlebar settings
$titlebar-title-color: $topmenu-text;
$titlebar-title-hover: $topmenu-text;
$titlebar-background: $topmenu-background; // override foundation settings
$titlebar-icon-color: $topmenu-text; // override foundation settings
$titlebar-icon-color-hover: $topmenu-text; // override foundation settings
//$titlebar-padding: 0.5rem; // override foundation settings
$titlebar-padding: 0;
// Topbar settings
$topbar-title-color: $topmenu-text;
$topbar-title-hover: $topmenu-text;
$topbar-colour: $topmenu-text;
$topbar-background: $topmenu-background; // override foundation settings
$topbar-colour-hover: $topmenu-text;
$topbar-background-hover: $topmenu-hover;
$topbar-colour-current: $topmenu-current-text;
$topbar-background-current: $topmenu-background-current;
$topbar-submenu-colour: $topmenu-text;
$topbar-submenu-background: $topmenu-background; // override foundation settings
$topbar-submenu-colour-hover: $topmenu-text;
$topbar-submenu-background-hover: $topmenu-hover;
$topbar-submenu-current-colour: $topmenu-current-text;
$topbar-submenu-current-background: $topmenu-background-current;
$topbar-padding: 0; // override foundation settings
$drilldown-colour: $topmenu-text;
$drilldown-background: $topmenu-background;
$drilldown-colour-hover: $topmenu-text;
$drilldown-background-hover: $topmenu-hover;
$drilldown-current-colour: $topmenu-current-text;
$drilldown-current-background: $topmenu-background-current;
$dropdownmenu-padding: $topmenu-topbar-padding; // override foundation settings

//	3. Accordion
// -----------------------------------------------------------------------1

$accordionColorDefault: $primary-color;
$accordionBackgroundDefault: $body-background;
$accordionHoverBackgroundDefault: $light-gray;
$accordionRadius: $global-radius;
$accordionRadiusOption: 0.6rem;
$accordionShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);

//	4. Tabs
// -----------------------------------------------------------------------1

$tabsColorDefault: color-pick-contrast($primary-color);
$tabsBackgroundDefault: $primary-color;
$tabsHoverBackgroundDefault: $light-gray;
$tabsRadius: $global-radius;
$tabsRadiusOption: 0.6rem;
$tabsShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);

//  5. Callouts
// -----------------------------------------------------------------------5

$calloutRadius: $global-radius;
$calloutRadiusOption: 0.6rem;
$calloutShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);
$calloutFullOption: true;

//  5. Cards
// -----------------------------------------------------------------------5

$cardRadius: $global-radius;
$cardRadiusOption: 0.6rem;
$cardShadowOption: 0 10px 20px rgba(0, 0, 0, 0.2);

//	6. Other bizazz settings
// -----------------------------------------------------------------------1

//	7. Overrides of foundation settings
// -----------------------------------------------------------------------3

//	Buttons
//$button-background: getColour(colour2, color);
//$button-background-hover: scale-color($button-background, $lightness: -15%);
//$button-color: $white;
$button-radius: 5px;
//$button-padding: 1rem 2rem;

// Link color
//$anchor-color: $primary-color;
//$anchor-color: getColour(colour2, color);
//$anchor-color-hover: scale-color($anchor-color, $lightness: 14%);
//$anchor-text-decoration: underline;
//$anchor-text-decoration-hover: underline;

// Lists
//$list-side-margin: 1.25rem; // set ol, ul margin-left
$list-side-margin: 2.25rem;
//$list-nested-side-margin: 1.25rem;
$list-nested-side-margin: 2.25rem;
$list-side-margin-small: 1.25rem; // set ol, ul margin-left on small screen
$list-nested-side-margin-small: 1.25rem;

// Forms
$form-label-font-size: rem-calc(16);
$input-font-size: rem-calc(16);

// Other
//$button-background-hover: scale-color($button-background, $lightness: +32%);
//$button-background-hover-lightness: -25%;
$global-radius: 0.6rem;
// off canvas width
$offcanvas-sizes: (
  small: 300px,
  medium: 400px,
);

$header-styles: (
  small: (
    'h1': ('font-size': 22),
    'h2': ('font-size': 20),
    'h3': ('font-size': 19),
    'h4': ('font-size': 18),
    'h5': ('font-size': 17),
    'h6': ('font-size': 16),
  ),
  medium: (
    'h1': ('font-size': 42),
    'h2': ('font-size': 36),
    'h3': ('font-size': 31),
    'h4': ('font-size': 25),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16),
  ),
);
